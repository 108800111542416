<template>
  <div class="w-full">
    <search-input
      class="mb-8"
      @input="onSearch($event, 100)"
      placeholder="Roles"
    />
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <template v-else>
      <div v-for="designation in designations" :key="designation.id" class="flex flex-col border-b border-dashed border-romanSilver p-2">
        <div class="w-full flex flex-row justify-between items-center gap-2">
          <div class="flex flex-row justify-start items-start gap-2">
            <div class="flex justify-center w-9 h-9 rounded-md">
              <span class="w-9 h-9 text-blueCrayola border text-center font-semibold rounded-md p-1 flex justify-center items-center">
                {{ $getInitials(designation.name) }}
              </span>
            </div>
            <div class="flex flex-col flex-grow">
              <p>{{ designation.name }}</p>
              <p class="text-xs flex font-semibold text-romanSilver uppercase">
                {{ designation.orgFunction.name }} • {{ designation.level.name }}
              </p>
            </div>
          </div>
          <input
            type="radio"
            :value="designation"
            v-model="selectedDesignation"
            @input="$emit('selected', designation)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";

export default {
  components: {
    SearchInput
  },
  data(){
    return {
      selectedDesignation: {},
      designations: [],
      timeout: null,
      loading: true
    }
  },
  methods: {
    onSearch(query, items) {
      this.loading = true
      clearTimeout(this.timeout);
      if (!query) {
        this.$_getDesignations(`&perPage=${items}`)
        .then(({ data }) => {
          this.designations = data.designations
          this.loading = false
        })
      }
      this.timeout = setTimeout(() => {
        this.$_getDesignations(
          `&search=${query}&perPage=${items}`
        ).then(({ data }) => {
          this.designations = data.designations
          this.loading = false
        })
      }, 500)
    }
  },

  mounted() {
    this.onSearch('', 3);
  }
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}
input[type="radio"]:checked {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
